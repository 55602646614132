
import { defineComponent, onMounted, reactive } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { MenuComponent } from "@/assets/ts/components";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import ApiService from "@/core/services/ApiService";
import { ErrorMessage, Field, Form } from "vee-validate";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";

import DetailLegalitas from "@/components/1crm/1sekolah/legalitas/detail-legalitas.vue";
import DetailTableLegalitas from "@/components/1crm/1sekolah/legalitas/detail-table-legalitas.vue";

export default defineComponent({
  name: "kt-widget-11",
  components: {
    Field,
    DetailLegalitas,
    DetailTableLegalitas,
  },
  props: {
    widgetClasses: String,
  },

  beforeMount() {
    store.dispatch(Actions.VERIFY_AUTH_BANK);
    setCurrentPageBreadcrumbs("Detail Legalitas", ["Data"]);
  },
});
